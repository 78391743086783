<template>
  <b-card>
    <b-form>
      <validation-observer ref="simpleRules">
        <form @submit.prevent>
          <b-row>
            <b-col :cols="type === 'taskTemplate' ? 6 : 12" v-if="type !== 'taskActivity'">
              <b-form-group id="input-group-1" label="Title" label-for="input-title">
                <validation-provider #default="{ errors }" name="Title" rules="required">
                  <b-form-input :disabled="isEmployee() ? true : false" id="input-title" v-model="form.title" type="text"
                    placeholder="Task title"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" v-if="type === 'taskTemplate'">
              <b-form-group label="Project">
                <validation-provider #default="{ errors }" name="Project" rules="required">
                  <v-select :disabled="isEmployee() ? true : false" v-model="selectedProject" :options="projects"
                    :reduce="(project) => project.id" label="title" multiple placeholder="Select the project"
                    :clearable="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="type !== 'taskActivity'">
              <b-form-group id="input-group-2" label="Description" label-for="input-description">
                <validation-provider #default="{ errors }" name="Description" rules="required">
                  <quill-editor :disabled="isEmployee() ? true : false" style="min-height: 200px" ref="quilContent"
                    id="quil-content" v-model="form.description" :options="editorOption" class="border-bottom-0" />
                  <div id="quill-toolbar" class="d-flex justify-content-end border-top-0">
                    <!-- Add a bold button -->
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                    <button class="ql-align" value="" />
                    <button class="ql-align" value="center" />
                    <button class="ql-align" value="right" />
                    <button class="ql-align" value="justify" />
                    <button class="ql-link" />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="type !== 'taskTemplate'">
              <b-form-group label="Owners">
                <v-select :multiple="true" :disabled="isEmployee() ? true : false" v-model="selectedOwners"
                  :options="users" label="name" placeholder="Select the owners" :clearable="false"></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="type !== 'taskTemplate'">
              <b-form-group label="Priority">
                <v-select :disabled="isEmployee() ? true : false" v-model="selectedPriority" :options="priority"
                  label="name" :clearable="false" placeholder="Select the priority"></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="type !== 'taskTemplate' && id">
              <b-form-group v-if="currentUser.roles[0].name !== 'EMPLOYEE'" label="Status">
                <v-select :placeholder="placeholderStatus ? placeholderStatus.label : null" v-model="form.status"
                  :options="allStatus" :clearable="false" />
              </b-form-group>
              <b-form-group v-else label="Status">
                <v-select :placeholder="placeholderStatus ? placeholderStatus.label : null" v-model="form.status"
                  :options="employeeStatus" :clearable="false" />
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="type !== 'taskTemplate'">
              <b-form-group label="Price">
                <b-form-input :disabled="isEmployee() ? true : false" type="number" v-model="form.price"
                  @keydown="filtrarEntrada" placeholder="Example: 1500.00" />
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="type !== 'taskTemplate'">
              <label>Duration</label>
              <b-form-group>
                <b-form-input :disabled="isEmployee() ? true : false" type="number" v-model="form.duration"
                  placeholder="Duration value in hours" />
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="type !== 'taskTemplate' && id">
              <b-form-group label="End date" id="input-group-3" label-for="input-end_date">
                <b-form-datepicker :disabled="isEmployee() ? true : false" id="date"
                  v-model="form.end_date"></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                @click="create">Guardar</b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                @click="cancel">Cancelar</b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BCalendar,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { taskStatusOptions } from "@/utils/status";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import { required } from "@validations";

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BCalendar,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      type: null,
      fk_activity: null,
      fk_task_template: null,
      form: {
        title: null,
        description: null,
        end_date: null,
        price: null,
        duration: null,
        timer: null,
        status: { label: "TO DO", key: "to_do" },
      },
      selectedOwners: null,
      selectedProject: null,
      selectedPriority: null,
      statusOpt: null,
      priority: ["high", "medium", "low"],
      required,
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: "Insert the task description",
      },
      placeholderStatus: null,
      status_timer: null,
      employeeStatus: taskStatusOptions["employee"],
      allStatus: taskStatusOptions["all"],
      timer: null
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.usersConfig.currentUser;
    },
    users() {
      return this.$store.state.usersConfig.users ?
        this.$store.state.usersConfig.users
          .filter((user) => user.status === 1) :
        []
    },
    task() {
      return this.$store.state.taskConfig.task;
    },
    taskTemplate() {
      return this.$store.state.taskTemplateConfig.taskTemplate;
    },
    taskActivity() {
      return this.$store.state.taskActivityConfig.taskActivity;
    },
    projects() {
      return this.$store.state.projectConfig.projects;
    },
  },

  async created() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.fk_activity = this.$route.query.activity;

    await Promise.all([
      this.$store.dispatch("getAllUsers"),
      this.$store.dispatch("getProjects"),
    ]);

    if (this.$route.name !== "tasksUpdate") return;

    try {
      if (this.type === "taskTemplate") {
        await this.$store.dispatch("getTaskTemplate", this.id);

        this.form.title = this.taskTemplate.title;
        this.form.description = this.taskTemplate.description;
        this.selectedProject = this.taskTemplate.projects.map(
          (project) => project.id
        );
      } else if (this.type === "taskActivity") {
        await this.$store.dispatch("getTaskActivity", this.id);
        this.fk_activity = this.taskActivity.fk_activity;
        this.fk_task_template = this.taskActivity.fk_task_template;
        this.placeholderStatus = this.allStatus.find(
          (s) => s.key === this.taskActivity.status
        );
        this.form.status = this.allStatus.find(
          (s) => s.key === this.taskActivity.status
        ) ?? { label: this.placeholderStatus.label, key: this.placeholderStatus.key };
        this.form.end_date = this.taskActivity.endDate;
        this.selectedOwners = this.taskActivity.owners;
        this.form.price = this.taskActivity.price;
        this.form.duration = this.taskActivity.duration;
        this.form.timer = this.taskActivity.timer;
        this.selectedPriority = this.taskActivity.priority;
        this.status_timer = this.taskActivity.status_timer;
      } else {
        await this.$store.dispatch("getTask", this.id);

        this.fk_activity = this.task.fk_activity;
        this.form.title = this.task.title;
        this.form.description = this.task.description;
        this.placeholderStatus = this.allStatus.find(
          (s) => s.key === this.task.status
        );
        this.form.status = this.allStatus.find(
          (s) => s.key === this.task.status
        ) ?? { label: this.placeholderStatus.label, key: this.placeholderStatus.key };
        this.form.end_date = this.task.end_date;
        this.selectedOwners = this.task.owners;
        this.form.price = this.task.price;
        this.form.timer = this.task.timer;
        this.form.duration = this.task.duration;
        this.selectedPriority = this.task.priority;
        this.status_timer = this.task.status_timer;
      }
    } catch (err) {
      this.$router.push("/error-404");
    }
  },

  methods: {
    isEmployee() {
      return this.currentUser.roles[0].name === "EMPLOYEE";
    },
    cancel() {
      const nextRoute = decodeURIComponent(this.$route.query.next || "/tasks");
      this.$router.push(nextRoute);
    },
    async create() {
      const isValid = await this.$refs.simpleRules.validate();
      if (isValid) {
        const data = {
          id: this.id ?? null,
          fk_activity: this.fk_activity ?? null,
          fk_task_template: this.fk_task_template ?? null,
          title: this.form.title,
          description: this.form.description ?? null,
          end_date: this.form.end_date ?? null,
          status: this.form.status ? this.form.status.key : "to_do",
          fk_projects: this.selectedProject,
          owners: this.selectedOwners ? this.selectedOwners.map(o => o.id) : [],
          price: this.form.price,
          duration: this.form.duration,
          priority: this.selectedPriority ?? null,
        };

        if (this.type === "taskTemplate" && this.$route.name !== "tasksUpdate")
          await this.$store.dispatch("createTaskTemplate", data);
        else if (this.type === "taskTemplate")
          await this.$store.dispatch("updateTaskTemplate", data);

        if (this.type === "taskActivity" && this.$route.name !== "tasksUpdate")
          await this.$store.dispatch("createTaskActivity", data);
        else if (this.type === "taskActivity")
          await this.$store.dispatch("updateTaskActivity", data);

        if (this.type === "task" && this.$route.name !== "tasksUpdate")
          await this.$store.dispatch("createTask", data);
        else if (this.type === "task")
          await this.$store.dispatch("updateTask", data);

        if (data.status !== 'on_going' && this.status_timer && this.status_timer === 1) {
          if (this.type === "taskActivity") this.$store.dispatch('editTaskActivity', { id: this.id, date_timer: new Date().toISOString() })
          else this.$store.dispatch('editTask', { id: this.id, date_timer: new Date().toISOString() })

          this.$store.dispatch('createChangelog', {
            fk_user: this.currentUser.id,
            local: 'task',
            type: 'update',
            entity_id: this.id,
            action: 'Task - ' + data.title + ': Stop Timer'
          })
        }

        const nextRoute = decodeURIComponent(
          this.$route.query.next || "/tasks"
        );
        this.$router.push(nextRoute);
      }
    },

    filtrarEntrada(event) {
      const codigoTecla = event.keyCode || event.which;
      const tecla = String.fromCharCode(codigoTecla);

      if (!/^\d+$/.test(tecla) && ![8, 37, 38, 39, 40].includes(codigoTecla)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}



#quil-content ::v-deep {
  .ql-disabled {
    background-color: #efefefef;
  }

  >.ql-container {
    border-bottom: 0;
    min-height: inherit;
  }

  +#quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
